<template>
    <div class="signup-form">
        <form @submit.prevent="handleSubmit()">
            <div class="row">
                <div class="col-md-12 mb-2">
                    <ui-input
                        id="email"
                        v-model="formData.email"
                        name="email"
                        type="text"
                        placeholder="email@example.com"
                        :label="$t('Email')"
                        :field="v$.formData.email"
                        :debounce="500"
                    />
                </div>
                <div class="col-md-6 mb-2">
                    <ui-input
                        id="name"
                        v-model="formData.name"
                        name="name"
                        type="text"
                        placeholder="John"
                        :label="$t('Name')"
                        :field="v$.formData.name"
                    />
                </div>
                <div class="col-md-6 mb-2">
                    <ui-input
                        id="lastName"
                        v-model="formData.lastName"
                        name="lastName"
                        type="text"
                        placeholder="Doe"
                        :label="$t('Last name')"
                        :field="v$.formData.lastName"
                    />
                </div>
                <div class="col-md-6 mb-2">
                    <ui-select
                        v-model="formData.country"
                        :label="$t('Country')"
                        :options="countryList"
                        :field="v$.formData.country"
                        :placeholder="$t('Choose an option')"
                        option-label="name"
                        option-value="code"
                        @change="setPhoneCode"
                    />
                </div>
                <div class="col-md-6 mb-2">
                    <ui-input
                        id="phone"
                        v-model="formData.phone"
                        name="phone"
                        type="tel"
                        placeholder="55 1254 5678"
                        :label="$t('Phone')"
                        :field="v$.formData.phone"
                    >
                        <template v-if="formData.phoneCode" #prepend>
                            +{{ formData.phoneCode }}
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-6 mb-2">
                    <ui-input
                        id="password"
                        v-model="formData.password"
                        name="password"
                        type="password"
                        placeholder="********"
                        class="mb-2"
                        :label="$t('Password')"
                        :field="v$.formData.password"
                    />
                </div>
                <div class="col-md-6 mb-2">
                    <ui-input
                        id="passwordConfirmation"
                        v-model="formData.passwordConfirmation"
                        name="passwordConfirmation"
                        type="password"
                        placeholder="********"
                        class="mb-2"
                        :label="$t('Password')"
                        :field="v$.formData.passwordConfirmation"
                    />
                </div>
                <div class="col-md-12 mb-2">
                    <ui-checkbox
                        id="accept_privacy_notes"
                        v-model="formData.accept_privacy_notes"
                        name="accept_privacy_notes"
                        :checked-value="true"
                        :unchecked-value="false"
                        :field="v$.formData.accept_privacy_notes"
                    >
                        <span>
                            {{ $t('I have read and accepted the') }}
                            <a v-sanitize-href="getCheckboxLink('privacy-policy')" class="signup-form__link" target="_blank" rel="noopener">
                                {{ $t('Privacy notice') }}
                            </a>
                        </span>
                    </ui-checkbox>
                </div>
                <div class="col-md-12 mb-2">
                    <ui-checkbox
                        id="accept_terms_conditions"
                        v-model="formData.accept_terms_conditions"
                        name="accept_terms_conditions"
                        :checked-value="true"
                        :unchecked-value="false"
                        :field="v$.formData.accept_terms_conditions"
                    >
                        <span>
                            {{ $t('I have read and accepted the') }}
                            <a v-sanitize-href="getCheckboxLink('terms-and-conditions')" class="signup-form__link" target="_blank" rel="noopener">
                                {{ $t('Terms and conditions') }}
                            </a>
                        </span>
                    </ui-checkbox>
                </div>
            </div>
            <ui-button :loading="registering" class="w-100 mb-3" type="submit">
                {{ $t('Signup') }}
            </ui-button>
        </form>
        <vue-recaptcha
            class="w-100"
            ref="recaptcha"
            size="invisible"
            @verify="signUp"
            :sitekey="CONST.CAPTCHACODE"
            :loadRecaptchaScript="true"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, maxLength, minLength, required, sameAs } from '@vuelidate/validators';
import VueRecaptcha from 'vue-recaptcha';

import UiButton from '@/components/ui/buttons/Button.vue';
import UiCheckbox from '@/components/ui/inputs/Checkbox.vue';
import UiSelect from '@/components/ui/inputs/Select.vue';
import UiInput from '@/components/ui/inputs/Input.vue';

import googleMixin from '@/plugins/mixin/google.mixin';

import recaptchaUtils from '@/utils/recaptcha.utils';

export default {
    name: 'SignUpForm',
    components: {
        UiButton,
        UiCheckbox,
        UiSelect,
        UiInput,
        VueRecaptcha,
    },
    mixins: [googleMixin],
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            countryList: [],
            disabled: {
                email: false,
            },
            formData: {
                email: '',
                password: '',
                name: '',
                lastName: '',
                passwordConfirmation: '',
                country: null,
                phone: '',
                phoneCode: '',
                accept_terms_conditions: false,
                accept_privacy_notes: false,
                person_type: '',
            },
            registering: false,
            show: {
                password: false,
                repeatPassword: false,
            },
            signupErrors: {
                email: false,
            },
            signupErrorsMessages: {
                email: '',
            },
            user: {
                siteId: null,
                redirect_url: null,
            },
        };
    },
    computed: {
        ...mapGetters('ui', ['language', 'sites']),
    },
    validations() {
        return {
            formData: {
                email: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('email') }), required),
                    email: helpers.withMessage(this.$t('Email invalid'), email),
                    availableEmail: helpers.withMessage(
                        this.$t('The email already exists'),
                        helpers.withAsync(async value => {
                            try {
                                if (!value) {
                                    return false;
                                }

                                const config = { auth: { username: value, password: '' } };
                                await this.axiosAccount.get('/api/accounts/validate/email', config);

                                return false;
                            } catch(error) {
                                return error?.response?.status === 404;
                            }
                        })
                    ),
                    $autoDirty: true,
                },
                name: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('name') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(2)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(35)),
                    $autoDirty: true,
                },
                lastName: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('last name') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(2)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(35)),
                    $autoDirty: true,
                },
                country: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('country') }), required),
                    $autoDirty: true,
                },
                phone: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('phone') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(8)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(12)),
                    $autoDirty: true,
                },
                password: {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('password') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(8)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(25)),
                    valid: helpers.withMessage(this.$t('Password must include upper and lower case letters, numbers, and special characters'), value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[[\]@$!%*¡¿?&#=|.(){}:;<>,/~_+-])([A-Za-z\d[\]@$!%*¡¿?&#=|.(){}:;<>,/~_+-]|[^ ]){8,25}$/.test(value)),
                    $autoDirty: true,
                },
                passwordConfirmation: {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('password confirmation') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(8)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(25)),
                    sameAs: helpers.withMessage(this.$t('Passwords do not match'), sameAs(this.formData.password)),
                    $autoDirty: true,
                },
                accept_terms_conditions: {
                    sameAs: helpers.withMessage(this.$t('It is necessary to accept the terms and conditions'), sameAs(true)),
                    $autoDirty: true,
                },
                accept_privacy_notes: {
                    sameAs: helpers.withMessage(this.$t('It is necessary to accept the privacy notice'), sameAs(true)),
                    $autoDirty: true,
                },
            },
        };
    },
    async mounted() {
        this.countryList = await this.fnApiGetCountries();

        if (this.$route.query.email) {
            this.formData.email = this.$route.query.email;
        }

        if (this.$route.query.country) {
            this.formData.country = this.$route.query.country;
            this.setPhoneCode(this.$route.query.country);
        }

        if (this.$route.query.first_name) {
            this.formData.name = this.$route.query.first_name;
        }

        if (this.$route.query.last_name) {
            this.formData.lastName = this.$route.query.last_name;
        }

        if (this.$route.query.phone) {
            this.formData.phone = this.$route.query.phone;
        }

        if (this.$route.query.redirect_url) {
            this.user.redirect_url = this.$route.query.redirect_url;
        }

        if (this.$route.query.site_id) {
            this.user.siteId = this.$route.query.site_id;
        }
    },
    methods: {
        getCheckboxLink(type) {
            const { site_id } = this.$route.query;
            const site = this.sites.find(site => site.site_id === site_id);

            if (site) {
                const site_name = site.name.toLowerCase().replace(' ', '-');

                if (this.$te(`${site_name}.${type}`)) {
                    return this.$t(`${site_name}.${type}`);
                }
            }

            return this.$t(`accounts.${type}`);
        },
        setPhoneCode(country_code) {
            const country = this.countryList.find(country => country.code === country_code);
            this.formData.phoneCode = country?.phone_code || '';
        },
        async handleSubmit() {
            this.v$.$touch();

            if (this.v$.$invalid) {
                return;
            }

            if (!recaptchaUtils.isAvailable()) {
                this.signUp('');
                return;
            }

            this.$refs.recaptcha.reset();
            this.$refs.recaptcha.execute();
        },
        async signUp(token) {
            this.registering = true;

            try {
                const payload = {
                    first_name: this.formData.name,
                    last_name: this.formData.lastName,
                    country: this.formData.country,
                    phone: this.formData.phone,
                    accept_privacy_notes: this.formData.accept_privacy_notes,
                    accept_terms_conditions: this.formData.accept_terms_conditions,
                    token,
                };

                await this.axiosAccount.post('/api/register', payload, {
                    auth: {
                        username: this.formData.email,
                        password: this.formData.password,
                    },
                });

                const { site_id, redirect_url } = this.$route.query;
                const formatted_url = new URL('/new-user', window.location.origin);

                formatted_url.searchParams.append('email', this.formData.email);

                if (site_id) {
                    formatted_url.searchParams.append('site_id', site_id);
                }

                if (redirect_url) {
                    formatted_url.searchParams.append('redirect_url', redirect_url);
                }
                
                this.$router.push(formatted_url.pathname + formatted_url.search);
            } catch(error) {
                this.showError(error);
            } finally {
                this.registering = false;
            }
        },
    },
};
</script>

<style lang="scss">
.signup-form {
    &__link {
        color: #004C74;
        font-weight: 600;
    }
}
</style>
