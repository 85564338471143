<template>
    <div class="signup-page">
        <logos-container />
        <tracking-alert />
        <h1 class="signup-page__title">{{ $t('Sign up') }}</h1>
        <p class="signup-page__text">{{ $t('Welcome, please enter your information') }}</p>
        <sign-up-form />
        <div v-if="!itsAnIframe" class="signup-page__social-buttons">
            <google-login-button v-if="!isRunningInWebView" />
            <web-view-google-login-button v-else />
        </div>
        <p class="signup-page__signup">
            {{ $t('I have an account!') }}
            <router-link :to="{ path: '/login', query: { ...$route.query }}" class="signup-page__link">
                {{ $t('Login') }}
            </router-link>
        </p>
    </div>
</template>

<script>
import GoogleLoginButton from '@/components/ui/GoogleLoginButton.vue';
import LogosContainer from '@/components/ui/LogosContainer.vue';
import SignUpForm from '@/components/forms/SignUp.vue';
import TrackingAlert from '@/components/envia/TrackingAlert.vue';
import WebViewGoogleLoginButton from '@/components/web-view/GoogleLoginButton.vue';

import iframeMixin from '@/plugins/mixin/iframe.mixin';
import webViewMixin from '@/plugins/mixin/web-view.mixin';

export default {
    name: 'signup-page',
    components: {
        GoogleLoginButton,
        LogosContainer,
        SignUpForm,
        TrackingAlert,
        WebViewGoogleLoginButton,
    },
    mixins: [iframeMixin, webViewMixin],
    data() {
        return {
            validatingEmail: false,
            user: {
                email: '',
            },
        };
    },
};
</script>

<style lang="scss">
.signup-page {
    text-align: left;

    &--mb-20 {
        margin-bottom: 20px;
    }

    &__logo {
        margin-bottom: 35px;
    }

    &__title {
        font-size: 35px;
        font-weight: 600;
    }

    &__text {
        color: #787F84;
        font-size: 16px;
        margin-bottom: 35px;
    }

    &__social-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        margin-bottom: 30px;
    }

    &__signup {
        color: #9093A1;
        font-size: 16px;
        font-weight: 400;
    }

    &__link {
        color: #004C74;
        font-weight: 600;
    }

}
</style>
