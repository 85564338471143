<template>
    <div class="ui-checkbox">
        <label class="ui-checkbox__label" :for="id">
                <input
                    :id="id"
                    v-model="checked"
                    class="ui-checkbox__input"
                    type="checkbox"
                    :class="{
                        'ui-checkbox__input--valid': field && field.$dirty && !field.$error,
                        'ui-checkbox__input--invalid': field && field.$dirty && field.$error,
                    }"
                    :name="name"
                    :checked="checked"
                    @change="onChange"
                />
            <slot>Checkbox</slot>
        </label>
        <ui-invalid-feedback class="mt-0" :field="field" />
    </div>
</template>

<script>
import UiInvalidFeedback from '@/components/ui/InvalidFeedback.vue';

export default {
    name: 'UiCheckbox',
    components: { UiInvalidFeedback },
    props: {
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            default: '',
            required: false,
        },
        name: {
            type: String,
            default: '',
            required: false,
        },
        uncheckedValue: {
            type: [String, Boolean, Number],
            default: false,
            required: false,
        },
        checkedValue: {
            type: [String, Boolean, Number],
            default: true,
            required: false,
        },
    },
    data() {
        return {
            checked: false,
        };
    },
    methods: {
        onChange() {
            const new_value = this.checked ? this.checkedValue : this.uncheckedValue;

            this.$emit('input', new_value);
        },
    },
};
</script>

<style lang="scss">
.ui-checkbox {
    &__label {
        align-items: center;
        color: $accounts-secondary-400;
        display: inline-flex;
        font-size: 0.9rem;
        gap: 8px;
        margin-bottom: 0;
    }

    &__input {
        appearance: none;
        border: 1px solid $accounts-secondary-600;
        border-radius: 4px;
        cursor: pointer;
        height: 18px;
        line-height: 1;
        margin: 0;
        outline: none;
        position: relative;
        width: 18px;

        &:focus {
            outline: none;
        }

        &:checked {
            background-color: $accounts-primary-600;
            border-color: $accounts-primary-600;

            &::before {
                align-items: center;
                color: $white;
                content: '\f00c';
                display: flex;
                font-family: 'Font Awesome 5 Free', sans-serif;
                font-size: 10px;
                font-weight: 900;
                height: 100%;
                justify-content: center;
                left: 0;
                line-height: 1;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus {
                border-color: $general-error;
            }
        }
    }
}
</style>
